html {
  height: 100%;
}

body {
  font-family: "Raleway", sans-serif;
  min-height: 100%;
}

button {
  background-color: transparent;
  border: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-transform: none;
}

.sr-only {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}

.no-list-styling {
  list-style: none;
  padding-inline-start: 0;
}

#root {
  height: 100%;
}
